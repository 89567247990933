@import "settings";
@import "_common/grid";

// About items
@import "_box/about-items-box";
@import "_box/programs-box";

// News
@import "_box/news-box";
@import "_parts/post-cart";

.about-page {
  .block-title {
    @include respond-property('font-size', 90px, 70px, 55px, 55px, 40px, 35px);
    font-family: "Monly", sans-serif;

    @include respond-down(S){
      line-height: 1em;
      margin-bottom: 0.2em;
    }
  }

  .block-subtitle {
    color: #373737;
  }
}
