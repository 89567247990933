@font-face {
    font-family: "Monly";
    src: url("Monly-Free-Bold.otf");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: "Monly";
    src: url("Monly-Free-Light.otf");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

/*
@font-face {
	font-family: "Monly", serif;
	src: url("Monly-Serif-Free-Bold.otf");
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}
@font-face {
	font-family: "Monly", serif;
	src: url("Monly-Serif-Free-Light.otf");
	font-style: normal;
	font-weight: 200;
    font-display: swap;
}
*/
