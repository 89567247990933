@import "_box/programs-box";

.type-badge {
  @include respond-property('height', 30px, 30px, 24px, 20px, 20px, 20px);
  @include respond-property('font-size', 16px, 16px, 14px, 12px, 12px, 12px);
  color: #FFF;
  font-weight: bold;
  padding: 0 1em;
  align-items: center;
  border-radius: 4px;
  background: #FF5D5D;
  display: inline-flex;
}

.date-badge {
  color: #B2B2B2;
  font-size: 14px;
  letter-spacing: 0.014em;
  padding-top: 0.2em;

  @include respond-down(L){
    font-size: 12px;
  }
}

.news-list {
  .row {
    display: flex;
    margin: 0 0px;

    @include respond-down(L){
      margin: 0 -10px;
    }
    @include respond-down(S){
      flex-wrap: wrap;
    }

    &.row-many {
      flex-wrap: wrap;
      margin-bottom: 5em;

      .small-wrapper {
        margin-bottom: 1.4em;

        .news-item, .news-item-image .image {
          @include respond-property('height', 416px, 416px, 290px, 250px, 250px, 270px);
        }
      }
    }
  }

  .large-wrapper {
    flex: 1 1 75%;
    padding: 0 16px;

    @include respond-down(L){
      padding: 0 10px;
    }
    @include respond-down(M){
      flex: 1 1 66.666%;
    }
    @include respond-down(S){
      flex: 0 0 100%;
    }
  }

  .medium-wrapper {
    flex: 1 1 50%;
    padding: 0 16px;

    @include respond-down(L){
      padding: 0 10px;
    }
    @include respond-down(M){
      flex: 1 1 66.666%;
    }
  }

  .small-wrapper {
    max-width: 25%;
    flex: 1 1 25%;
    padding: 0 16px;

    @include respond-down(L){
      padding: 0 10px;
    }
    @include respond-down(M){
      flex: 1 1 33.333%;
      max-width: 33.3333%;
    }
    @include respond-down(S){
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include respond-down(XS){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .titled-item {
    @include respond-property('margin-bottom', 80px, 66px, 50px, 40px, 34px, 26px);

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .subtitle {
        margin-bottom: 0;
      }

      .button {
        @include respond-down(S){
          display: none;
        }
      }
    }
  }

  .show-all-block {
    display: flex;
    justify-content: space-around;
    flex: 0 0 100%;

    @include respond-up(M){
      display: none;
    }
  }

  /* --------------------------------------------------------------------- */
  // Social

  .full-height {
    display: flex;
    flex-direction: column;

    & > * {
      flex: 1 1 100%;
    }
  }

  .news-items-wrapper {
    @include respond-down(S){
      order: 2;
    }

    .news-items {
      margin: 0 -16px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -32px;

      @include respond-down(L){
        margin: 0 -10px;
        margin-bottom: -20px;
      }
      @include respond-down(S){
        margin-bottom: 10px;
      }

      .news-item-wrapper {
        flex: 0 0 33.333%;
        padding: 0 16px;

        @include respond-down(M){
          flex: 0 0 50%;
        }
        @include respond-down(XS){
          flex: 0 0 100%;
        }

        &.item-5, &.item-6 {
          @include respond-down(M){
            display: none;
          }
        }
        &.item-3, &.item-4 {
          @include respond-down(S){
            display: none;
          }
        }

        @include respond-down(L){
          padding: 0 10px;
        }
      }
    }
  }

  .show-small-only {
    @include respond-up(M){
      display: none;
    }
    @include respond-up(L){
      display: none;
    }
    //@include respond-down(L){
    //  display: none;
    //}
    //@include respond-down(M){
    //  display: none;
    //}
  }

  .subscribe-wrapper {
    display: flex;
    flex-direction: column;

    @include respond-down(S){
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    @include respond-down(XS){
      margin-bottom: 14px;
    }

    .subscribe {
      @include respond-property('padding', 28px 22px, 26px 24px, 20px 16px, 20px 16px, 18px 20px, 16px 20px);
      flex: 0 0 100%;
      background: #FFEF60;
      border-radius: 9px;

      @include respond-down(L){
        border-radius: 6px;
      }

      .subscribe-title {
        @include respond-property('height', 30px, 30px, 26px, 26px, 34px, 34px);
        @include respond-property('font-size', 16px, 16px, 14px, 13px, 16px, 16px);
        @include respond-property('margin-bottom', 26px, 26px, 20px, 16px, 22px, 14px);
        border-radius: 6px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 800;
        letter-spacing: 0.018em;
        padding-top: 0.1em;

        @include respond(S){
          max-width: 280px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .posts-list {
        @include respond-down(S) {
          display: none;
        }
      }

      .social-links {
        @include respond-property('margin-bottom', 28px, 25px, 20px, 20px);
        display: flex;
        align-items: center;
        justify-content: center;

        .link {
          text-align: center;
          padding: 0 15px;

          svg {
            width: 44px;
            height: 44px;
            margin-bottom: 6px;

            .figure {
              fill: #A6DD5C;
              transition: fill 0.25s ease;
            }

            @include respond(L){
              width: 30px;
              height: 30px;
            }
            @include respond(M){
              width: 30px;
              height: 30px;
            }
          }

          .text {
            color: #17C256;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.004em;
            display: block;
            transition: color 0.25s ease;

            @include respond(L){
              font-size: 12px;
            }
            @include respond(M){
              font-size: 12px;
            }
          }

          &:hover {
            svg .figure {
              fill: #17556A
            }

            .text {
              color: #17556A;
            }
          }
        }
      }
    }
  }

  .posts-list {
    &.posts-list-after {
      order: 10;
      margin-bottom: 20px;

      @include respond-up(M){
        display: none;
      }
    }

    .item {
      display: block;
      color: #464646;
      border-bottom: 1px solid rgba(70, 70, 70, 0.19);
      padding-bottom: 22px;
      margin-bottom: 22px;

      @include respond-down(L){
        padding-bottom: 11px;
        margin-bottom: 12px;
      }
      @include respond-down(M){
        padding-bottom: 10px;
        margin-bottom: 11px;
      }
      @include respond-down(S){
        padding-bottom: 16px;
        margin-bottom: 18px;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: -10px;
        border-bottom: none;
      }

      &.item-5, &.item-6 {
        @include respond-up(L){
          display: none;
        }
      }
      &.item-10, &.item-11, &.item-12, &.item-13 {
        @include respond-down(M){
          display: none;
        }
      }
      &.item-9 {
        @include respond-down(M){
          padding-bottom: 0;
          margin-bottom: -10px;
          border-bottom: none;
        }
      }

      .title {
        font-size: 16px;
        font-weight: 800;
        line-height: 1.35em;
        max-height: 41px;
        overflow: hidden;
        margin-bottom: 0.8em;
        display: block;
        transition: color 0.25s ease;

        @include respond-down(L){
          font-size: 12px;
          max-height: 32px;
        }
        @include respond-down(S){
          font-size: 14px;
          max-height: unset;
        }
      }

      .helper {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 0.3em;

          @include respond-down(L){
            width: 16px;
            height: 16px;
          }

          path.bg {
            fill: #464646;
            transition: fill 0.25s ease;
          }
        }

        .date {
          font-size: 14px;
          letter-spacing: 0.018em;
          padding-top: 0.1em;

          @include respond-down(L){
            font-size: 11px;
          }
        }
      }

      &:hover, &.hover {
        .title {
          color: #17556A;
        }

        .helper svg path.bg {
          fill: #17556A;
        }
      }

    }

  }

  /* --------------------------------------------------------------------- */
  // Sales

  .banner-list-image {
    width: 125%;

    @include respond-down(M){
      width: 100%;
    }
    @include respond-down(S){
      display: none;
    }

    .image {
      @include respond-property('height', 460px, 460px, 330px, 338px);
      @include respond-property('padding', 22px, 16px, 13px, 13px);
      background: #EEE no-repeat center center;
      background-size: cover;
      position: relative;
      width: 100%;
      border-radius: 9px;
      display: block;
      overflow: hidden;

      @include respond-down(L){
        border-radius: 6px;
      }

      &:not([data-index="0"]){
        display: none;
      }

      .gradient {
        @include respond-up(L){
          display: none;
        }
      }

      .title-on-slide {
        position: absolute;
        z-index: 50;
        bottom: 0;
        width: 60%;
        top: 0;
        align-items: flex-end;
        display: flex;
        text-transform: uppercase;
        color: #FFF;
        font-weight: bold;
        line-height: 1.35em;
        margin-bottom: 0.5em;
        text-shadow: 0 2px 3px rgba(0,0,0,0.27);

        @include respond-up(L){
          display: none;
        }
      }
    }
  }

  .banner-list-text-wrapper {
    @include respond-down(M){
      display: none;
    }
  }

  .banner-list-text {
    @include respond-property('padding', 32px, 28px, 20px);
    border-radius: 9px;
    border: 4px solid #FF5D5D;
    margin: 48px 0;
    position: relative;
    z-index: 20;
    background: #FFF;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    @include respond-down(L){
      margin: 38px 0;
      border-radius: 6px;
      border: 3px solid #FF5D5D;
    }

    @include respond-down(M){
      display: none;
    }

    &:not([data-index="0"]){
      display: none;
    }

    .title {
      @include respond-property('font-size', 20px, 20px, 16px);
      color: #17556A;
      text-transform: uppercase;
      line-height: 1.35em;
    }

    .text-body {
      @include respond-property('font-size', 16px, 16px, 12px);
      color: #464646;
      line-height: 1.35em;
      margin-bottom: 30px;
    }

    .banner-footer {
      @include respond-property('padding', 0 32px, 0 28px, 0 20px);
      position: absolute;
      bottom: 16px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrows {
        margin: 0 -5px;

        .to-left, .to-right {
          display: inline-block;
          padding: 5px;
          cursor: pointer;
          transition: transform 0.25s ease;

          svg {
            @include respond-down(L){
              width: 8px;
            }
            path {
              stroke: #FF5D5D;
            }
          }
        }

        .to-left {
          &:not(.disabled):hover {
            transform: translateX(-5px);
          }
          svg {
            transform: rotate(180deg);
          }
        }
        .to-right {
          &:not(.disabled):hover {
            transform: translateX(5px);
          }
        }
      }
    }
  }

  .banner-sales {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-down(S){
      flex-wrap: wrap;
      flex: 1 1 100%;
      flex-direction: initial;
      margin: 0 -10px;
      padding-bottom: 20px;
    }
    @include respond-down(XS){
      padding-bottom: 0;
    }

    .news-item-wrapper {
      margin-bottom: 0;

      .gradient {
        top: 50%;
      }

      @include respond-down(S){
        flex: 0 0 50%;
        padding: 0 10px;
      }
      @include respond-down(XS){
        flex: 0 0 100%;
        margin-bottom: 20px;
      }
    }
  }

  /* --------------------------------------------------------------------- */
  // Events

  .events-container {
    .item-4 {
      @include respond(M) {
        display: none;
      }
    }

    .item-3, .item-4 {
      @include respond(XS) {
        display: none;
      }
    }

    .news-item-wrapper {
      .news-item, .news-item-image {
        @include respond-property('height', 416px, 416px, 290px, 250px, 250px, 270px);
      }
      .news-item-image {
        .image {
          height: 100%;
        }
      }
    }
  }

  /* --------------------------------------------------------------------- */
  // Banner

  .subscribe-banner {
    @include respond-property('height', 110px, 100px, 80px, 88px);
    @include respond-property('padding', 0 50px, 0 48px, 0 16px 0 50px, 0 26px 0 40px);
    @include respond-property('margin-bottom', 64px, 62px, 44px, 42px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 9px;
    background: #FFEF60;

    @include respond-down(L){
      border-radius: 6px;
    }
    @include respond-down(S){
      display: none;
    }

    .lead {
      @include respond-property('font-size', 35px, 30px, 25px, 25px);
      position: absolute;
      padding-left: 40px;
      left: 0;
      z-index: 20;
      color: #17AB4E;
      font-weight: 800;
      width: 50%;
      transition: opacity 0.15s ease, transform 0.35s ease, width 0.45s ease;

      @include respond-down(L){
        width: 38%;
      }
      @include respond-down(M){

      }
    }

    .diagonal-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      width: 100%;
      z-index: 10;

      .diagonal, .diagonal:after, .diagonal:before {
        width: 30px;
        height: 600px;
        background: #FFF;
        content: '';
        position: absolute;
      }
      .diagonal {
        margin-left: 20%;
        margin-top: -200px;
        transform: rotate(-45deg);
        transition: margin-left 0.55s ease, transform 0.55s ease;

        @include respond-down(L){
          transform: rotate(-45deg) scaleX(0.7);
        }

        &:before {
          left: -60px;
        }
        &:after {
          left: 60px;
        }
      }
    }

    .subscribe-form {
      position: relative;
      z-index: 20;
      width: 47%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: width 0.35s ease;

      @include respond-down(L){
        width: 54%;
      }
      @include respond-down(M){
        width: 60%;
      }

      .input-wrapper {
        position: relative;
        z-index: 20;
        overflow: hidden;
        flex: 1 1 100%;
        padding: 5px 20px 5px 80px;

        @include respond-down(L){
          padding: 5px 16px 5px 70px;
        }

        .subscribe-input {
          height: 56px;
          border-radius: 9px;
          opacity: 0.5;
          transition: box-shadow 0.25s ease, margin-left 0.45s ease, opacity 0.45s ease;
          margin-left: -550px;

          @include respond-down(XL){
            height: 52px;
            margin-left: -450px;
          }
          @include respond-down(L){
            height: 42px;
            border-radius: 6px;
            margin-left: -350px;
          }
          @include respond-down(M){
            height: 52px;
          }

          &:hover {
            box-shadow: inset 0 0 0 4px rgba(23, 171, 78, 0.27);
          }
          &:active, &:focus {
            box-shadow: inset 0 0 0 4px rgba(23, 171, 78, 0.7);
          }
        }
      }

      svg {
        z-index: 50;
        position: absolute;
        top: -50px;
        left: -50px;

        @include respond-down(XL){
          top: -40px;
        }
        @include respond-down(L){
          width: 120px;
        }
      }

      .button {
        max-width: unset;
        padding-left: 1.5em;
        padding-right: 1.5em;
        justify-content: center;
        min-width: unset;

        .to-hide {
          white-space: nowrap;
          opacity: 1;
          overflow: hidden;
          display: inline-block;
          transition: opacity 0.15s ease, max-width 0.35s ease;
          max-width: 160px;
        }
      }
    }

    &:not(.inactive) {
      .lead {
        @include respond-down(M){
          opacity: 0;
          transform: translateX(-150px);
        }
      }

      .diagonal-wrapper {
        .diagonal {
          margin-left: 72%;
          transform: rotate(-40deg) scaleX(1.3);

          @include respond-down(L){
            transform: rotate(-40deg) scaleX(1.1);
          }
          @include respond-down(M){
            margin-left: 52%;
          }
        }
      }

      .subscribe-form {
        @include respond-down(M){
          width: 90%;
        }

        .button {
          .to-hide {
            max-width: 0;
            opacity: 0;
          }
        }

        .input-wrapper {
          .subscribe-input {
            opacity: 1;
            margin-left: 0;
          }
        }
      }
    }
  }

  /* --------------------------------------------------------------------- */
  // Articles
  .articles-posts {
    padding-top: 1em;

    @include respond-down(S){
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
  }
}

.post-image-wrapper {
  @include respond-property('margin-bottom', 62px, 54px, 42px, 40px, 33px, 20px);
  width: 100%;
  padding-bottom: 52%;
  height: auto;
  position: relative;
  background: #eee no-repeat center center;
  background-size: cover;
  border-radius: 9px;
  overflow: hidden;

  .post-image-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @include respond-down(XS){
      align-items: flex-start;
      padding: 14px;
    }
  }

  .type-badge {
    @include respond-property('height', 60px, 60px, 48px, 40px, 40px, 40px);
    @include respond-property('font-size', 32px, 32px, 28px, 14px, 14px, 14px);
    position: relative;
    z-index: 30;

  }

  .gradient {
    border-radius: 9px;
    top: 50%;
  }

  .tech-image {
    @include respond-property('width',  200px, 200px, 100px, 100px, 84px, 74px);
    @include respond-property('height', 200px, 200px, 100px, 100px, 84px, 74px);
    border-radius: 200px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 50px auto;
    //position: relative;
    z-index: 20;
    position: absolute;
    left: 20px;
    top: 20px;

    @include respond-down(M){
      margin-bottom: 20px;
    }
    @include respond-down(XS){
      position: absolute;
      left: auto;
      right: 14px;
      top: 14px;
    }

    img {
      width: 70%;
    }
  }

  .tech-image-big {
    //@include respond-property('width',  96px, 76px, 56px, 56px, 56px, 76px);
    //@include respond-property('height', 96px, 76px, 56px, 56px, 56px, 76px);
    width: 30%;
    height: 30%;
    //left: 16px;
    //top: 16px;
    //position: absolute;
    z-index: 60;
    //background: #FFF;
    //border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 60%;
    }
  }


  .group {
    position: relative;
    z-index: 30;
    text-align: center;

    @include respond-down(XS){
      text-align: left;
    }

    .post-title {
      @include respond-property('font-size', 50px, 40px, 35px, 30px, 25px, 20px);
      text-shadow: 0 2px 3px rgba(0,0,0,0.27);
      letter-spacing: 0.018em;
      font-weight: 900;
      color: #FFF;
      margin-bottom: 0.8em;

      @include respond-down(XS){
        margin-bottom: 0.4em;
      }
    }

    .post-date {
      font-size: 14px;
      color: #FFF;

      @include respond-down(XL){
        font-size: 12px;
      }
    }
  }
}

.post-content {
  margin-bottom: 20px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  font-size: 20px;
  line-height: 1.38em;
  /* max-width: 970px; */
  position: relative;

  @include respond-down(M) {
    font-size: 14px;
  }

  &.post-margin {
    margin-bottom: 80px;
    @include respond-property('margin-bottom', 116px, 110px);
  }

  ol > li, ul > li {
    margin-bottom: 0.4em;
  }

  a {
    color: #6FECDB;

    &:not(.button):hover {
      color: #17556A;
    }
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 500px;
  }

  p {
    // margin-bottom: 0.4em !important;
    // line-height: 1.7em;
    @include respond-down(S){
	margin-bottom: 0.8em !important;
        line-height: 1.7em;
    }
  }

  .lead {
    line-height: 1.35em;
    margin-bottom: 0.6em;
    font-size: 25px;
    font-weight: bold;
    color: #17556A;
    padding-top: 2em;

    @include respond-down(L) {
      font-size: 20px;
    }
  }

  .images-how-to {
    display: flex;
    margin: 0 -12px;
    margin-bottom: 1.5em;

    @include respond-down(S) {
      flex-direction: column;
    }

    .img-wrapper {
      border-radius: 9px;
      overflow: hidden;
      margin: 0 12px;

      &.first {
        @include respond-down(S) {
          max-width: 300px;
          display: block;
          margin: 0 auto;
          margin-bottom: 15px;
        }

        img {
          @include respond-down(S) {
          }
        }
      }

      img {
        display: block;
        margin: 0;
        width: 100%;
      }
    }
  }
}

.table-sizes {
  margin: 0 100px 2em 100px;
  padding: 1em 0;
  position: relative;

  @include respond-down(XL){
    margin: 0 60px 2em 60px;
  }
  @include respond-down(L){
    margin: 0 0 2em 0;
  }
  @include respond-down(M){
    width: 472px;
    margin: 0 auto 2em auto;
  }
  @include respond-down(XS){
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }

  .helper {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.004em;
    color: #373737;
    display: flex;
    align-items: center;
    order: 1;
    margin-bottom: 0.5em;

    @include respond-down(L){
      font-size: 16px;
    }

    &.help-length {
      @include respond-down(M){
        position: absolute;
        right: 0;
        top: 1em;
      }
      @include respond-down(XS){
        position: initial;
        order: 2;
      }
    }

    .bullet {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: #BBF6EE;
      margin-right: 12px;

      &.red {
        background: #FC6D94;
      }

      @include respond-down(L){
        width: 10px;
        height: 10px;
      }
    }
  }

  .sizes-col-items {
    display: flex;
    margin: 0 -3px;
    padding: 10px 0;

    @include respond-down(M){
      flex-wrap: wrap;
    }
    @include respond-down(XS){
      order: 5;
    }

    .size-col {
      padding: 3px;
      flex: 1 1 20%;

      @include respond-down(M){
        flex: 1 1 8.32%;
      }
      @include respond-down(XS){
        flex: 1 1 12.5%;
      }

      .n {
        height: 44px;
        margin: 8px 0;
        background: #BBF6EE;
        font-size: 16px;
        color: #17556A;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 0.1em;

        @include respond-down(M){
          height: 36px;
          font-size: 14px;
        }
        @include respond-down(XS){
          height: 32px;
        }

        &.red {
          background: #FC6D94;
          color: #FFF;
          font-weight: 500;

          @include respond-down(M){
            font-size: 16px;
          }
        }
      }
    }
  }
}

.theme-social {
  .head .subtitle:before,
  .type-badge {
    background: #FFEF60;
    color: #373737;
  }
}

.theme-sales {
  .head .subtitle:before,
  .button,
  .type-badge {
    background: #FF5D5D;
  }

  .news-item-wrapper {
    .news-item {
      border: 4px solid #FF5D5D;

      @include respond-down(L){
        border: 3px solid #FF5D5D;
      }
      .news-body .footer svg path {
        stroke: #FF5D5D;
      }
    }
  }
}

.theme-events {
  .head .subtitle:before,
  .button,
  .type-badge {
    background: #16E3C8;
  }

  .gradient, .gradient:after {
    background: linear-gradient(to bottom,
            rgba(22, 227, 200, 0) 0%,
            rgba(22, 227, 200, 0.15) 30%,
            rgba(22, 227, 200, 0.75) 70%,
            rgba(22, 227, 200, 0.90) 100%);
  }

  .news-item-wrapper {
    .news-item {
      border: 4px solid #16E3C8;

      @include respond-down(L){
        border: 3px solid #16E3C8;
      }
      .news-body .footer svg path {
        stroke: #16E3C8;
      }
    }
  }
}

.theme-articles {
  .head .subtitle:before,
  .button,
  .type-badge {
    background: #62E994;
  }

  .banner-list-text-wrapper .banner-list-text {
    border: 4px solid #62E994;

    @include respond-down(L){
      border: 3px solid #62E994;
    }

    .banner-footer .arrows {
      .to-left, .to-right {
        svg path {
          stroke: #62E994;
        }
      }
    }
  }

  .news-item-wrapper {
    .news-item {
      border: 4px solid #62E994;

      @include respond-down(L){
        border: 3px solid #62E994;
      }
      .news-body .footer svg path {
        stroke: #62E994;
      }
    }
  }
}

.theme-techs {
  .head .subtitle:before,
  .button,
  .type-badge {
    background: #FC6D94;
  }
}
