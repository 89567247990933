.programs-box {
  @include respond-property('margin-bottom', 100px, 100px, 100px, 100px, 100px, 500px);

  .block-title {
    @include respond-property('font-size', 90px, 70px, 55px, 55px, 40px, 35px);
    font-family: "Monly", sans-serif;

    @include respond-down(S){
      line-height: 1em;
      margin-bottom: 0.2em;
    }
  }

  .border-radius-10 {
    border-radius: 10px;
  }

  .programs-columns {
    display: flex;
    margin: 0 -28px;

    @include respond-down(XL) {
      margin: 0 -20px;
    }

    @include respond-down(M){
      flex-direction: column;
      margin: 0;
    }

    .program-wrapper {
      border-radius: 9px;
      padding: 0 28px;
      flex: 0 0 33.33%;
      position: relative;

      @include respond-down(XL){
        padding: 0 20px;
      }
      @include respond-down(M){
        padding: 0;
      }

      .item {
        padding: 12px 10px;
        @include respond-property('padding', 30px, 14px 20px);
        @include respond-property('height', 320px, 230px, 192px, 162px, 162px, 160px);
        border-radius: 9px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @include respond-down(M){
          margin-bottom: 22px;
          flex-direction: initial;
          padding-right: 40px;
        }
        @include respond-down(XS){
          margin-bottom: 24px;
        }

        .p-title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 1 100%;
          @include respond-property('margin-bottom', 30px, 10px, 0, 0, 0, 0);

          img {
            @include respond-property('max-height', 120px, 90px, 70px, 90px, 74px, 74px);
          }

          .text {
            @include respond-property('font-size', 55px, 41px, 36px, 45px, 40px, 35px);
            font-family: "Monly", sans-serif;
            text-align: left;
            line-height: 1.0em;
            font-weight: bold;
            color: #17556A;
            padding: 15px;

            @include respond-down(L){
              width: 70%;
            }
          }
        }

        .button {
          flex: 0 0 auto;

          @include respond-down(S){
            display: none;
          }
        }

        &.child-0 {
          background: #FFEF60;
        }
        &.child-1 {
          background: #FBDEED;
        }
        &.child-2 {
          background: #BBF6EE;

          .p-title img {
            @include respond-property('max-height', 146px, 114px, 80px, 114px, 90px, 90px);
          }
        }
      }
    }

    .program-col-wrapper {
      @include respond-property('font-size', 25px, 20px, 18px, 20px, 18px, 14px);

      @include respond-down(M){
        flex: 0 0 100%;
        margin-bottom: 20px;
      }

      @include respond-down(S){
        margin-bottom: 12px;
      }

      .p-title {
        height: 136px;
        line-height: 1.35em;
        padding: 30px 0 30px 100px;
        position: relative;
        color: #17556A;
        font-weight: 800;
        display: flex;
        align-items: center;

        @include respond-down(M){
          padding: 20px 0 20px 86px;
          height: 120px;
          position: absolute;
          z-index: 60;
          bottom: 0;
          left: 30px;
        }
        @include respond-down(XS){
          height: 100px;
          padding: 0 30px 0 60px;
          margin-bottom: 20px;
        }

        svg {
          @include respond-property('width', 90px, 80px, 76px, 80px, 76px, 50px);
          position: absolute;
          z-index: 30;
          left: 10px;
          bottom: 30px;
        }

        .text-bg {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .word {
            position: relative;
            display: inline-block;
            margin: 0 4px;

            &:after {
              content: '';
              position: absolute;
              left: -0.9em;
              top: -0.5em;
              bottom: -0.5em;
              right: -0.9em;
              border-radius: 6px;
              background: #FFEF60;
            }

            .text {
              position: relative;
              z-index: 10;
            }
          }
        }
      }

      &:nth-child(2) {
        .p-title .text-bg .word:after {
          background: #FBDEED;
        }
      }

      &:nth-child(3) {
        .p-title .text-bg .word:after {
          background: #BBF6EE;
        }
      }

      .program-image {
        @include respond-property('height', 300px, 260px, 220px, 340px, 264px, 194px);
        border-radius: 9px;
        background: #eee no-repeat center center;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .sale-mark {
          z-index: 50;
          position: absolute;
          top: 0;
          right: 30px;
          border-radius: 0 0 6px 6px;
          background: #FC6D94;
          letter-spacing: 0.085em;
          font-weight: 800;
          color: #FFF;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 20px;
        }

        .gradient, .gradient:after {
          background: linear-gradient(to bottom,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.05) 30%,
                  rgba(0, 0, 0, 0.35) 70%,
                  rgba(0, 0, 0, 0.40) 100%);
        }

        .button {
          z-index: 60;
          position: absolute;
          right: 10px;
          bottom: 10px;
          background: transparent;
        }
      }
    }
  }
}