.about-page {
  .h1 {
    @include respond_property('font-size', 50px, 40px, 35px, 35px, 30px, 25px);
    font-weight: 900;
    color: #17556A;
    text-align: center;
    margin-bottom: 10px;
  }

  .about-items-box {
    max-width: 1360px;
    margin: 0 auto 70px auto;
    position: relative;
    padding: 20px 0;

    @include respond-down(L){
      margin-bottom: 50px;
    }
    @include respond-down(S){
      padding: 10px 0;
    }

    .about-line-vertical {
      position: absolute;
      left: 50%;
      width: 460px;
      margin-left: -170px;
      top: 160px;
      height: 1500px;
      z-index: 5;

      @include respond-down(XL){
        width: 380px;
        margin-left: -120px;
        top: 150px;
        height: 1300px;
      }

      @include respond-down(L){
        width: 300px;
        margin-left: -120px;
        top: 150px;
        height: 1000px;
      }
      @include respond-down(M){
        display: none;
      }
    }

    .about-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 10;

      @include respond-down(S){
        flex-direction: column;
        max-width: 380px;
        margin: auto;
      }

      .calibri-left {
        position: absolute;
        bottom: -10px;
        right: 150px;

        @include respond-down(XL){
          width: 80px;
          bottom: -20px;
        }
        @include respond-down(L){
          bottom: -50px;
        }
        @include respond-down(M){
          display: none;
        }
      }

      .pict {
        flex: 0 0 40%;

        @include respond-down(M){
          flex: 0 0 50%;
        }
        @include respond-down(S){
          flex: 0 0 100%;
        }

        img {
          width: 100%;
          display: block;
          margin: -5px 0;

          @include respond-down(S){
            margin: 0;
          }
        }
      }

      .text {
        flex: 0 0 44%;
        padding: 0 70px;
        position: relative;

        @include respond-down(L){
          padding: 70px 50px;
        }
        @include respond-down(M){
          flex: 0 0 48%;
          padding: 30px 20px;
        }
        @include respond-down(S){
          flex: 0 0 100%;
          order: 2;
          padding: 20px 30px;
          margin-bottom: 20px;
        }
        @include respond-down(XS){
          padding: 20px 0;
        }

        & > svg {
          position: absolute;
          top: -20px;
          transform: scale(1.4);

          @include respond-down(L){
            transform: scale(1);
          }

          @include respond-down(M){
            display: none;
          }
        }

        &.right-decor > svg {
          right: -10px;
        }

        &.left-decor > svg {
          left: -10px;
        }

        .h3 {
          @include respond_property('font-size', 25px, 20px);
          font-size: 18px;
          margin-bottom: 1.1em;
          color: #17556A;
        }

        .info {
          @include respond_property('font-size', 18px, 16px);
          font-size: 14px;
          color: #464646;
          line-height: 1.65em;
          letter-spacing: 0.004em;

          strong {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .follow-block {
    @include respond_property('margin-bottom', 90px, 70px, 70px, 60px, 50px, 45px);

    .h2 {
      @include respond_property('font-size', 25px, 20px);
      font-size: 18px;
      text-align: center;
      color: #17C256;
      margin-bottom: 30px;

      @include respond-down(L){
        margin-bottom: 24px;
      }
    }

    .social-links {

      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 300px;
      margin: auto;

      svg.calibri {
        position: absolute;
        left: -120px;
        top: 0px;

        @include respond-down(M){
          left: -80px;
        }

        @include respond-down(S){
          left: -40px;
        }

        @include respond-down(XS){
          display: none;
        }
      }

      .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        color: #17C256;
        font-weight: bold;
        text-decoration: underline;
        transition: color 0.25s ease;

        svg {
          margin-bottom: 10px;

          @include respond-down(M){
            width: 48px;
            height: 48px;
          }

          .figure {
            transition: fill 0.25s ease;
          }
        }

        &:hover {
          color: #3CE5CE;

          svg .figure {
            fill: #3CE5CE;
          }
        }
      }
    }

  }
}
