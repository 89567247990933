.news-item-wrapper {
  margin-bottom: 32px;

  @include respond-down(L){
    margin-bottom: 20px;
  }

  // Default item
  .news-item {
    border: 4px solid #FBDEED;
    border-radius: 9px;

    @include respond-down(L){
      border-radius: 6px;
      border: 3px solid #FBDEED;
    }

    .news-image {
      @include respond-property('height', 220px, 220px, 156px, 130px, 134px, 150px);
      border-radius: 5px 5px 0 0;
      background: #EEE no-repeat center center;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-down(L){
        border-radius: 3px 3px 0 0;
      }

      .tech-image {
        @include respond-property('width',  96px, 76px, 56px, 56px, 56px, 76px);
        @include respond-property('height', 96px, 76px, 56px, 56px, 56px, 76px);
        left: 16px;
        top: 16px;
        position: absolute;
        z-index: 60;
        background: #FFF;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          display: block;
          width: 60%;
        }
      }

      .tech-image-big {
        //@include respond-property('width',  96px, 76px, 56px, 56px, 56px, 76px);
        //@include respond-property('height', 96px, 76px, 56px, 56px, 56px, 76px);
        width: 50%;
        height: 50%;
        //left: 16px;
        //top: 16px;
        //position: absolute;
        z-index: 60;
        //background: #FFF;
        //border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: 60%;
        }
      }

      .category {
        @include respond-property('left', 22px, 20px, 16px, 13px, 13px, 15px);
        @include respond-property('bottom', -15px, -15px, -12px, -10px, -10px, -10px);
        position: absolute;
        bottom: -50%;
        display: flex;

        svg {
          @include respond-property('width', 30px, 30px, 24px, 20px, 20px, 20px);
          @include respond-property('height', 30px, 30px, 24px, 20px, 20px, 20px);
          margin-right: 0.4em;
        }
      }
    }

    .news-body {
      @include respond-property('padding', 0 22px, 0 22px, 0 16px, 0 13px, 0 13px, 0 15px);
      position: relative;

      .title {
        @include respond-property('margin', 46px 0, 44px 0, 30px 0, 22px 0, 22px 0 10px 0, 24px 0 10px 0);
        @include respond-property('font-size', 16px, 16px, 12px, 12px, 14px, 14px);
        height: 3.8em;
        overflow: hidden;
        line-height: 1.35em;
        color: #464646;
      }

      .footer {
        @include respond-property('padding-bottom', 22px, 20px, 16px, 13px, 13px, 15px);
        justify-content: space-between;
        display: flex;
        align-items: center;

        & > svg {
          transition: transform 0.25s ease;
          @include respond-down(L){
            width: 8px;
          }

          path {
            stroke: #FC6D94;
          }
        }

        .group {
          display: flex;
          align-items: center;

          & > svg {
            margin-right: 0.75em;
            @include respond-property('width', 30px, 30px, 24px, 20px, 20px, 20px);
            @include respond-property('height', 30px, 30px, 24px, 20px, 20px, 20px);

            path.bg {
              fill: #B2B2B2;
              transition: fill 0.25s ease;
            }
          }
        }

        &:hover, &.hover {
          & > svg {
            transform: translateX(5px);
          }

          .group > svg path.bg {
            fill: #FC6D94;
          }
        }
      }
    }

    &.type-social {
      .news-image {
        .type-badge {
          background: #FFEF60;
          color: #373737;
        }
      }
    }

    &.type-round {
      border: none;

      .news-image {
        position: relative;
        border-radius: 300px;
        width: 300px;
        height: 300px;
        margin: 0 auto;

        @include respond-down(L){
          width: 220px;
          height: 220px;
        }
        @include respond-down(M){
          width: 180px;
          height: 180px;
        }

        .type-badge {
          background: #FFEF60;
          color: #373737;
        }

        .category {
          position: absolute;
          bottom: -40px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;

          @include respond-down(L){
            bottom: -34px;
          }
          @include respond-down(M){
            bottom: -28px;
          }
        }
      }

      .news-body {
        .title {
          padding-top: 0.4em;
          margin-bottom: 0;
          height: 3.1em;
          text-align: center;

          @include respond-down(L){
            padding-top: 1.0em;
            height: 3.7em;
          }
        }

        .footer {
          display: none;
        }
      }
    }

  }

  // Full-image item (small)
  .news-item-image {
    position: relative;
    border-radius: 9px;
    overflow: hidden;
    height: 100%;

    @include respond-down(L){
      border-radius: 6px;
    }

    .image {
      @include respond-property('height', 214px, 214px, 160px, 160px, 250px, 250px);
      @include respond-property('padding', 22px, 20px, 16px, 13px, 13px, 15px);
      background: #EEE no-repeat center center;
      background-size: cover;
      position: relative;
      z-index: 10;
    }

    .title {
      @include respond-property('font-size', 16px, 16px, 12px, 12px, 14px, 14px);
      @include respond-property('padding', 0 22px, 0 20px, 0 16px, 0 13px, 0 13px, 0 15px);
      margin-bottom: 1em;
      text-transform: uppercase;
      color: #FFF;
      font-weight: bold;
      line-height: 1.35em;
      text-shadow: 0 2px 3px rgba(0,0,0,0.27);
      position: absolute;
      z-index: 50;
      bottom: 0;
      width: 100%;
      top: 0;
      align-items: flex-end;
      display: flex;
      transition: transform 0.25s ease;
    }

    &.color-green-x {
      .image {
        .type-badge {
          background: #16E3C8;
          color: #FFF;
        }
      }
    }

    &:hover {
      .gradient {
        opacity: 1;
      }

      .title {
        transform: translateY(-5px);
      }
    }
  }
}
